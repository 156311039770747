import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { useUserStoreWithOut } from '@/store/modules/user'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue')
  },
  {
    path: '/spread',
    name: 'spread',
    component: () => import(/* webpackChunkName: "spread" */ '@/views/spread/index.vue')
  },
  {
    path: '/',
    name: 'promotion',
    redirect: '/login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/index.vue'),
    children: [
      {
        path: 'promotion',
        name: 'Promotion',
        component: () => import('@/views/promotion/index.vue'),
        meta: {
          keepAlive: true // 设置页面是否需要使用缓存
        }
      },
      {
        path: 'userInfo',
        name: 'UserInfo',
        component: () => import('@/views/userInfo/index.vue'),
        meta: {
          keepAlive: true // 设置页面是否需要使用缓存
        }
      }]
  },
  {
    path: '/agentManage',
    name: 'AgentManage',
    component: () => import(/* webpackChunkName: "about" */ '@/views/userInfo/agent/index.vue')
  },
  {
    path: '/agentInfo',
    name: 'AgentInfo',
    component: () => import(/* webpackChunkName: "about" */ '@/views/userInfo/agent/agentInfo.vue')
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: () => import(/* webpackChunkName: "about" */ '@/views/userInfo/wallet/index.vue')
  },
  {
    path: '/orderList',
    name: 'OrderList',
    component: () => import(/* webpackChunkName: "about" */ '@/views/userInfo/orderList/index.vue')
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: () => import(/* webpackChunkName: "about" */ '@/views/userInfo/withdraw/index.vue')
  },
  {
    path: '/myDetailInfo',
    name: 'MyDetailInfo',
    component: () => import(/* webpackChunkName: "about" */ '@/views/userInfo/myDetailInfo/index.vue')
  },
  {
    path: '/changePassword',
    name: 'ChangePassword',
    component: () => import(/* webpackChunkName: "about" */ '@/views/userInfo/changePassword/index.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(''),
  history: createWebHashHistory(),
  routes
})

const whiteList = ['/login', '/spread'] // no redirect whitelist
router.beforeEach(async (to, from, next) => {
  const store = useUserStoreWithOut()
  const token = store.getToken
  document.title = to.meta.title as string || '号卡管理系统'
  // 判断用户是不是通过修改URL地址进入的页面
  if (token) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/promotion' })
    } else {
      // next(`/login?redirect=${to.path}`)
      next()
    }
  } else {
    /* has no token */
    if (whiteList.includes(to.path)) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
    }
  }
})

export default router
