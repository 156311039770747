/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSysStoreWithOut } from '@/store/modules/sys'
import { Directive } from 'vue'
// instanceof ComponentPublicInstance {
//   const { setLoading } = useSysStoreWithOut()
//   const { el } = binding
//   if (binding.value) {
//     setLoading(true)
//     el.style.position = 'relative'
//     el.style.overflow = 'hidden'
//     el.style.pointerEvents = 'none'
//     el.style.opacity = '0.5'
//     el.style.filter = 'alpha(opacity=50)'
//     el.style.cursor = 'wait'
//     el.style.userSelect = 'none'

//     const loading = document.createElement('div')
//     loading.className = 'loading'
//     loading.style.position = 'absolute'
//     loading.style.top = '0'
//     loading.style.left = '0'
//     loading.style.width = '100%'
//     loading.style.height = '100%'

//     const loadingIcon = document.createElement('div')
//     loadingIcon.className = 'loading-icon'
//     loadingIcon.style.position = 'absolute'
//     loadingIcon.style.top = '50%'
//     loadingIcon.style.left = '50%'
//     loadingIcon.style.width = '40px'
//     loadingIcon.style.height = '40px'
//     loadingIcon.style.marginTop = '-20px'
//     loadingIcon.style.marginLeft = '-20px'
//     loadingIcon.style.border = '4px solid rgba(0, 0, 0, 0.1)'
//     loadingIcon.style.borderLeftColor = '#409EFF'
//     loadingIcon.style.borderRadius = '50%'
//     loadingIcon.style.animation = 'loading 1s linear infinite'

//     loading.appendChild(loadingIcon)
//     el.appendChild(loading)
//   } else {
//     setLoading(false)
//     el.style.position = ''
//     el.style.overflow = ''
//     el.style.pointerEvents = ''
//     el.style.opacity = ''
//     el.style.filter = ''
//     el.style.cursor = ''
//     el.style.userSelect = ''
//     const loading = el.querySelector('.loading')
//     if (loading) {
//       el.removeChild(loading)
//     }
//   }
// }
interface DirectiveBinding {
  value: any;
  oldValue: any;
  arg: string | null;
  modifiers: Record<string, boolean>;
  dir: Directive;
}

const loading = (el: HTMLElement, binding: DirectiveBinding) => {
  const { setLoading } = useSysStoreWithOut()
  if (binding.value) {
    setLoading(true)
    el.style.position = 'relative'
    el.style.overflow = 'hidden'
    el.style.pointerEvents = 'none'
    el.style.opacity = '0.5'
    el.style.filter = 'alpha(opacity=50)'
    el.style.cursor = 'wait'
    el.style.userSelect = 'none'

    const loading = document.createElement('div')
    loading.className = 'loading'
    loading.style.position = 'absolute'
    loading.style.top = '0'
    loading.style.left = '0'
    loading.style.width = '100%'
    loading.style.height = '100%'

    const loadingIcon = document.createElement('div')
    loadingIcon.className = 'loading-icon'
    loadingIcon.style.position = 'absolute'
    loadingIcon.style.top = '50%'
    loadingIcon.style.left = '50%'
    loadingIcon.style.width = '40px'
    loadingIcon.style.height = '40px'
    loadingIcon.style.marginTop = '-20px'
    loadingIcon.style.marginLeft = '-20px'
    loadingIcon.style.border = '4px solid rgba(0, 0, 0, 0.1)'
    loadingIcon.style.borderLeftColor = '#409EFF'
    loadingIcon.style.borderRadius = '50%'
    loadingIcon.style.animation = 'rotate 1s linear infinite'

    loading.appendChild(loadingIcon)
    el.appendChild(loading)
  } else {
    setLoading(false)
    el.style.position = ''
    el.style.overflow = ''
    el.style.pointerEvents = ''
    el.style.opacity = ''
    el.style.filter = ''
    el.style.cursor = ''
    el.style.userSelect = ''
    const loading = el.querySelector('.loading')
    if (loading) {
      el.removeChild(loading)
    }
  }
}
export default loading as Directive // 导出loading指令
